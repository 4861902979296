import type { ApplicationRecovery } from '~entities/application';
import {
  formatApplicationValuesByMapping,
  type ApplicationFormatter,
  type ApplicationRecoveryMapping,
  recoverApplicationValuesByMapping,
} from '~entities/application';

import { formatNumber, recoverNumber } from '~shared/formatters';

import type { FormValues } from '../loan-calc';

const mapping: ApplicationRecoveryMapping<Omit<FormValues, 'skipValidation'>> =
  {
    period: {
      path: ['requestedProduct', 'desiredLoanTerm'],
      format: formatNumber,
      recover: recoverNumber,
    },
    downPayment: {
      path: ['requestedProduct', 'downPayment'],
      format: formatNumber,
      recover: recoverNumber,
    },
    items: {
      path: ['orderItems'],
    },
  };

export const formatLoanCalc: ApplicationFormatter<FormValues> = (
  application,
  screenValues
) => {
  return formatApplicationValuesByMapping(application, screenValues, mapping);
};

export const recoverLoanCalc: ApplicationRecovery<FormValues> = (
  application
) => {
  return recoverApplicationValuesByMapping(application, mapping);
};
