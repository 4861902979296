import { environment } from '~app/environment';

import type { IdDocumentType } from '~entities/person';

import type { RadioOption } from '~shared/types/radio-option';

export type MainIdDocumentTypeValue = Extract<
  IdDocumentType,
  'UMID' | 'PRC' | 'SSS'
>;
export type OtherIdDocumentTypeValue = Extract<
  IdDocumentType,
  | 'PHIL_ID'
  | 'PHIL_SYS_ID'
  | 'DIGITAL_NATIONAL_PHIL_ID'
  | 'E_PHIL_ID'
  | 'DRIVING_LICENSE'
  | 'PASSPORT'
  | 'POSTAL_ID'
>;

export const idDocumentTypeMainOptions = [
  {
    label: 'UMID',
    value: 'UMID',
  },
  {
    label: 'SSS ID',
    value: 'SSS',
  },
  {
    label: 'PRC ID',
    value: 'PRC',
  },
] as RadioOption<MainIdDocumentTypeValue>[];

const newPhilIdOptions = [
  {
    label: 'PhilSys ID (Card type)',
    value: 'PHIL_SYS_ID',
  },
  {
    label: 'ePhil ID (Paper type)',
    value: 'E_PHIL_ID',
  },
  {
    label: 'Digital National ID',
    value: 'DIGITAL_NATIONAL_PHIL_ID',
  },
] as const;

const oldPhilIdOptions = [
  {
    label: 'PhilSys ID',
    value: 'PHIL_ID',
  },
] as const;

export const idDocumentTypeOtherOptions = [
  {
    label: 'Philippine passport',
    value: 'PASSPORT',
  },
  {
    label: "Driver's license",
    value: 'DRIVING_LICENSE',
  },
  ...(environment.IS_PHIL_ID_SUBTYPES_ENABLED
    ? newPhilIdOptions
    : oldPhilIdOptions),
  {
    label: 'Postal ID',
    value: 'POSTAL_ID',
  },
] as RadioOption<OtherIdDocumentTypeValue>[];
