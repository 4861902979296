import { useQueuedMutation } from '~shared/hooks';

import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';

import type {
  CreateApplicationPayload,
  SubmitApplicationPayload,
  UpdateApplicationPayload,
} from './application-api';
import { applicationKeys } from './application-keys';
import { gatewayApplicationApi } from './gateway-application-api';

import type { Application } from '../application';

export const useGetApplicationByIdQuery = (
  id: string,
  options?: Pick<UseQueryOptions<any, Error, Application>, 'refetchInterval'>
) => {
  return useQuery({
    queryKey: applicationKeys.application(id),
    queryFn: async () => gatewayApplicationApi.getApplicationById(id),
    ...options,
  });
};

export const useUpdateApplicationMutation = (
  options?: UseMutationOptions<void, Error, UpdateApplicationPayload>
) => {
  return useQueuedMutation({
    mutationFn: (application: UpdateApplicationPayload) =>
      gatewayApplicationApi.updateApplication(application),
    ...options,
  });
};

export const useCreateOrFindApplicationMutation = () => {
  return useMutation({
    mutationFn: async (payload: CreateApplicationPayload) =>
      gatewayApplicationApi.findOrCreateApplication(payload),
  });
};

export const useSubmitApplicationMutation = (
  options?: UseMutationOptions<any, Error, SubmitApplicationPayload>
) => {
  return useMutation({
    mutationFn: async (payload: SubmitApplicationPayload) =>
      gatewayApplicationApi.submitApplication(payload),
    ...options,
  });
};
