import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { FormProgressStepper } from '~widgets/application-form/form-progress-stepper';

import { useFormInitialize } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { Input, InputCapitalize, Select } from '@breeze/rhf-adapters';

import {
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  MIDDLE_NAME_FIELD_NAME,
  NICKNAME_FIELD_NAME,
  EMAIL_FIELD_NAME,
  CITIZENSHIP_FIELD_NAME,
} from '../constants/field-names';
import { citizenshipOptions } from '../lib';
import type { FormValues } from '../types';

type Props = {
  initialValue?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
};

export const PersonalDetails: React.FC<Props> = ({
  initialValue = { citizenship: 'PHL' },
  onSubmit,
  onFieldCompleted,
  onPrev,
}) => {
  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });

  useFormInitialize(methods, initialValue);

  const { control, handleSubmit, getValues } = methods;

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<HeaderWithLogo />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <div className="mb-5">
          <ScreenTitle
            title="Let’s start with the&nbsp;basics"
            subtitle="Introduce yourself so we can get to&nbsp;know you better"
            icon={<FormProgressStepper step="personal" />}
          />
        </div>
        <Controller
          name="firstName"
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({
                text: 'Enter your first name',
              }),
              validators.maxLength({ maxLength: 300 }),
              validators.filipinoName()
            ),
          }}
          render={(fieldProps) => (
            <InputCapitalize label={FIRST_NAME_FIELD_NAME} {...fieldProps} />
          )}
        />
        <Controller
          name="middleName"
          control={control}
          rules={{
            validate: combineValidators(
              validators.maxLength({ maxLength: 300 }),
              validators.filipinoName()
            ),
          }}
          render={(fieldProps) => (
            <InputCapitalize label={MIDDLE_NAME_FIELD_NAME} {...fieldProps} />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({ text: 'Enter your last name' }),
              validators.maxLength({ maxLength: 300 }),
              validators.filipinoName()
            ),
          }}
          render={(fieldProps) => (
            <InputCapitalize label={LAST_NAME_FIELD_NAME} {...fieldProps} />
          )}
        />
        <Controller
          name="citizenship"
          control={control}
          rules={{
            required: 'Select an option',
          }}
          render={(fieldProps) => (
            <Select
              label={CITIZENSHIP_FIELD_NAME}
              options={citizenshipOptions}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({ text: 'Enter your email' }),
              validators.email({
                invalidSymbolsText: 'Invalid characters',
                invalidFormatText: 'The format must be name@email.com',
              })
            ),
          }}
          render={(fieldProps) => (
            <Input type="email" label={EMAIL_FIELD_NAME} {...fieldProps} />
          )}
        />
        <Controller
          name="nickname"
          control={control}
          rules={{
            validate: combineValidators(
              validators.maxLength({ maxLength: 15 }),
              validators.filipinoName()
            ),
          }}
          render={(fieldProps) => (
            <InputCapitalize label={NICKNAME_FIELD_NAME} {...fieldProps} />
          )}
        />
      </Screen>
    </form>
  );
};
