import { useState } from 'react';

import type { Application } from '~entities/application';
import {
  isShortApplicationFlow,
  isPreapproved,
  isRejected,
} from '~entities/application/lib/status-checkers';

import { useOnMountEffect } from '~shared/hooks';
import errorImg from '~shared/images/error.webp';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { Button } from '~shared/ui/button';
import { Result } from '~shared/ui/result';

import { ButtonLink } from '@breeze-platform-ui/button';
import Tooltip from '@breeze-platform-ui/tooltip';
import { addDays, format } from 'date-fns';

import preapprovedImg from './images/pre-approved.webp';
import underReviewImg from './images/under-review.webp';
import { RejectionTooltip } from './rejection-reason-tooltip';
import { Timer } from './timer';

import {
  REVIEW_TIME_LIMIT_IN_SEC,
  getReviewTime,
  rejectionTimeoutPassed,
  reviewTimeExpired,
} from '../../lib';

export const ApplicationStatusUI: React.FC<Application> = (application) => {
  const { applicationStatusChangedUTC } = application;
  const statusChangedDate = new Date(
    nonNullableValue(applicationStatusChangedUTC)
  );
  const [reviewSeconds, setReviewSeconds] = useState(() =>
    getReviewTime(statusChangedDate)
  );

  useOnMountEffect(() => {
    const interval = setInterval(() => {
      setReviewSeconds((seconds) => seconds + 1);

      if (reviewTimeExpired(reviewSeconds)) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  const getContent = () => {
    if (isPreapproved(application)) {
      const hasMobileApp = isShortApplicationFlow(application);
      const message = hasMobileApp ? (
        <>To&nbsp;finish your application, go to&nbsp;the&nbsp;Skyro&nbsp;app</>
      ) : (
        <>To&nbsp;finish your application, install the&nbsp;Skyro&nbsp;app</>
      );
      const buttonText = hasMobileApp ? 'Open Skyro app' : 'Install the app';

      return (
        <Result
          title="Your application is pre-approved"
          subtitle={message}
          image={<img src={preapprovedImg} width={150} height={150} alt="" />}
          footer={
            <Button href="https://link.skyro.ph/lNBg/jtjfabpb" target="_blanc">
              {buttonText}
            </Button>
          }
        />
      );
    }

    if (isRejected(application) && rejectionTimeoutPassed(statusChangedDate)) {
      const nextApplicationTime = format(addDays(new Date(), 5), 'MMMM dd');

      return (
        <Result
          title="Your application wasn’t&nbsp;approved "
          subtitle={`You can try again after ${nextApplicationTime}`}
          image={errorImg}
          footer={
            <Tooltip
              popoverContent={<RejectionTooltip />}
              theme="dark"
              align="center"
              hideOnClickOutside
              hideOnTouchMove
              toggleOnClick
            >
              <ButtonLink size="m">Why was I not approved?</ButtonLink>
            </Tooltip>
          }
        />
      );
    }

    if (!reviewTimeExpired(reviewSeconds)) {
      return (
        <div className="flex flex-col items-center gap-10">
          <Timer
            seconds={reviewSeconds}
            limitInSeconds={REVIEW_TIME_LIMIT_IN_SEC}
          />
          <h2 className="text-2xl font-bold">Reviewing your data...</h2>
        </div>
      );
    }

    return (
      <Result
        title="Need more time to&nbsp;review your application"
        subtitle="We'll send you SMS with an&nbsp;update as&nbsp;soon&nbsp;as we finish reviewing it"
        image={underReviewImg}
      />
    );
  };

  return <div className="pt-[min(100px,_15%)]">{getContent()}</div>;
};
