export const environment = {
  // @ts-expect-error | Will be substituted during build. Defined in the vite.config.js
  APP_VERSION: __APP_VERSION__,
  // @ts-expect-error | Will be substituted during build. Defined in the vite.config.js
  FORM_VERSION: __FORM_VERSION__,
  ENV: import.meta.env.VITE_ENV,
  AUTH_CLIENT_ID: import.meta.env.VITE_AUTH_CLIENT_ID,
  API_HOST: import.meta.env.VITE_API_HOST,
  SENTRY_HOST: import.meta.env.VITE_SENTRY_HOST,
  LOGS_COLLECTOR_HOST: import.meta.env.VITE_LOGS_COLLECTOR_HOST,
  AMPLITUDE_ANALYTICS_KEY: import.meta.env.VITE_AMPLITUDE_ANALYTICS_KEY,
  USER_INACTIVE_THRESHOLD: import.meta.env.VITE_USER_INACTIVE_THRESHOLD,
  CAN_SKIP_VALIDATION: import.meta.env.VITE_SKIP_VALIDATION_TEST_ONLY === 'ON',
  IS_PHIL_ID_SUBTYPES_ENABLED: import.meta.env.VITE_PHIL_ID_SUBTYPES === 'ON',
};
