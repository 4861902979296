import { useMemo, useState } from 'react';

import type { OrderItem } from '~entities/order';

import { phMoneyProps } from '~shared/constants/ph-money-props';

import Money from '@breeze-platform-ui/money';

import deliveryImg from './images/delivery-stub.svg';
import orderItemImg from './images/order-item-stub.svg';

export const OrderItemUI: React.FC<{ item: OrderItem }> = ({ item }) => {
  const [isImageError, setIsImageError] = useState(false);
  const image = useMemo(() => {
    if (item.imageUrl) {
      return isImageError ? orderItemImg : item.imageUrl;
    }

    return item.category?.toLowerCase() === 'shipping'
      ? deliveryImg
      : orderItemImg;
  }, [isImageError, item]);
  const totalPrice = +item.price * item.quantity;
  return (
    <div className="flex items-start gap-4" data-qa-type="order-item">
      <img
        src={image}
        className="h-auto shrink self-center rounded-sm"
        onError={() => setIsImageError(true)}
        alt="item"
        width="40"
        height="40"
      />
      <div className="grow">
        <h6 className="text-m font-bold">{item.name}</h6>
        <p className="text-s">
          {item.quantity > 1 && (
            <span data-qa-type="order-item-quantity">
              {item.quantity}&nbsp;×&nbsp;
            </span>
          )}
          <Money
            dataQaType="order-item-price"
            {...phMoneyProps}
            value={item.price}
            precision={2}
          />
        </p>
      </div>
      <p className="text-m font-bold">
        <Money
          dataQaType="order-items-total-price"
          {...phMoneyProps}
          value={totalPrice}
          precision={2}
        />
      </p>
    </div>
  );
};
