import type { FeatureFlags } from '../api';

export const featureFlags: FeatureFlags = {
  scoringRules: {
    type: 'EXCLUDE',
    rules: [
      '514f0b1c-e272-4d8e-a531-5c45af5872fd',
      '9926c9eb-a1da-48dc-81b8-ec7ad1a7930b',
      'd77ad444-6551-4d96-a1c1-90e852313211',
      'c862029e-d126-4991-a00d-4231b55bd0fd',
      'c6d56aa4-f8ff-4482-b44c-8ab69590b8a7',
      'c37c7526-1649-4de9-935b-21e0794671c5',
      '53121fb4-0eb9-4bb9-be20-bece222aeecc',
    ],
  },
  skipProcessActivities: {
    InvokeDuplicatesScoringStage: {
      scoringResult: 'passed',
    },
    InvokeFreeScoringStrategy: {
      scoringResult: 'passed',
      authSelfieFaceId: '01GPWJGPETYJ0B0037DDSD1NK7',
    },
    InvokePartnerScoringStrategy: {
      scoringResult: 'passed',
    },
    InvokeFreeScoringStage2: {
      scoringResult: 'passed',
    },
    PrepareForDeviceScoring: {
      scoringResult: 'passed',
    },
    InvokeDeviceScoringStrategy: {
      scoringResult: 'passed',
    },
  },
  scoringAbTests: [],
};
