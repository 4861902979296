export const DOCUMENT_TYPE_FIELD_NAME = 'Type of document';
export const TAKE_PHOTO_FIELD_NAME = 'Take a photo of the document';
export const documentNumberFieldNames = {
  umid: 'CRN (Common Reference Number)',
  philId: 'PSN (PhilSys Number)',
  philSysId: 'PhilSys ID number',
  ePhilId: 'ePhil ID number',
  digitalNationalPhilId: 'Digital National ID number',
  prc: 'PRC license number',
  drivingLicence: 'License number',
  passport: 'Passport number',
  sss: 'SSS Number',
  postalId: 'Postal ID',
} as const;
